import { Injectable } from '@angular/core';
import { WhiteLabelAndCoBrandingModel } from './whit-label-and-cobranding.model';

@Injectable()
export class CommonService {

    public ClientId: number = 0;
    public controllerName: string = "";

    public xImage: string = "";

    public nDistId: number = 0;
    public DistWhiteLabelAndCoBranding: WhiteLabelAndCoBrandingModel = new WhiteLabelAndCoBrandingModel();

    Set(id: number) {
        this.ClientId = id
    }

    Get(): number {
        return this.ClientId;
    }

    setControllerName(name: string) {
        this.controllerName = name;
    }
    GetControllerName(): string {
        return this.controllerName;
    }

    setImage(image: string) {
        this.xImage = image;
    }

    getImage(): string {
        return this.xImage;
    }

    setCOBrandingData(cobranding: WhiteLabelAndCoBrandingModel) {
        this.DistWhiteLabelAndCoBranding = cobranding;
    }

    getCOBrandingData(): WhiteLabelAndCoBrandingModel {
        return this.DistWhiteLabelAndCoBranding;
    }

    sortAndDistinct(list, key) {
        var lookup = {};
        var items = list;
        var result = [];

        for (var item, i = 0; item = items[i++];) {
            if (item[key]) {
                var name = item[key].replace(/\s+/g, " ");

                if (!(name in lookup)) {
                    lookup[name] = 1;
                    result.push(item);
                }
            }
        }
        return result;
    }

    getPanNo(name: string) {
        debugger
        let panNo = "";
        let startIndex = name.indexOf("(");
        let secondStartIndex = name.indexOf("(",startIndex+1);
        let endIndex = name.indexOf(")");
        let secondEndIndex = name.indexOf(")",endIndex+1);
 
        if (secondStartIndex == null || secondStartIndex == -1)         //Niraj Updated Conditions for UCC Code
        {
            if (startIndex > -1 && endIndex > -1 && endIndex > startIndex) {
                panNo = name.substring(startIndex + 1, endIndex)
            }
   
        }
        else {
            panNo = name.substring(secondStartIndex + 1, secondEndIndex)
        }
 
        if (panNo == "") {
            panNo = name.substring(0, startIndex - 1)
        }
        return panNo
    }
}